import * as d3 from "d3"
import Color from "color"
import { TimelineData } from "./TimelineTypes"

/**
 * Draws a legend with items for each category found in the data
 *
 * @param legendRef Reference to legend group
 * @param canvasWidth Reference to canvas
 * @param data Data to draw legend for
 */
export default function drawLegend(
  legendRef: React.RefObject<SVGGElement>,
  canvasWidth: number,
  data: TimelineData
) {
  const xOffset: number = 200
  const yOffset: number = 70
  const boxHeight: number = 15
  const boxWidth: number = boxHeight
  const fontSize: number = 14

  const legend = d3.select(legendRef.current).selectAll("legend-items")

  /**
   * Wraps the legend items on the provided index
   *
   * @param wrapIdex The index to wrap on
   */
  function wrap(wrapIdex: number) {
    const nodes = d3.selectAll(".legend-item").nodes()

    for (let i = wrapIdex; i < data.length; ++i) {
      const lastSel = d3.select(nodes[i])
      console.log(lastSel.text())

      lastSel.attr("transform", function () {
        return `translate(${(i - wrapIdex) * xOffset}, ${2 * yOffset})`
      })
    }
  }

  // Clean up previous if exists
  d3.selectAll(".legend-item").remove()

  const legendItems = legend
    .data(data)
    .enter()
    .append("g")
    .classed("legend-item", true)
    .attr("transform", function (_, i) {
      return `translate(${i * xOffset}, ${yOffset})` // Position each group
    })

  legendItems
    .append("rect")
    .classed("legend-box", true)
    .attr("height", boxHeight)
    .attr("width", boxWidth)
    .attr("fill", function (d) {
      return d.color
    })
    .attr("stroke", function (d) {
      return new Color(d.color).darken(0.2).string()
    })

  legendItems
    .append("text")
    .classed("legend-text", true)
    .classed("test", false)
    .text(function (d) {
      return d.category
    })
    .attr("font-size", fontSize)
    .attr("x", boxWidth + 5) // Offset the text from the rectangle
    .attr("y", boxHeight / 1.2) // Center the text vertically

  // Determine from which item the legend is spilling outside the canvas
  let overflowItem = data.length
  while (overflowItem * xOffset > canvasWidth) {
    overflowItem--
  }
  wrap(overflowItem)
}
