import React, { useEffect, useRef } from "react"
import * as d3 from "d3"
import Color from "color"
import { EraFuncs, Eras } from "./EraFuncs"
import { navigate } from "gatsby"
import drawLegend from "./Legend"
import {
  TimelineCategory,
  TimelineData,
  TimelineItem,
  UnparsedTimlineData,
} from "./TimelineTypes"

interface Props {
  data: Array<TimelineCategory<number | string>>
  width: number
  height: number
}

export default function Timeline({ data, width, height }: Props): JSX.Element {
  const canvasRef = useRef<SVGSVGElement>(null)
  const axisRef = useRef<SVGGElement>(null)
  const barWrapperRef = useRef<SVGGElement>(null)
  const legendRef = useRef<SVGGElement>(null)
  const heightDelta: number = -30
  const scale: number = 0.15
  let lastZoomCoefficient: number

  useEffect((): void => {
    drawChart()
  }, [data])

  useEffect((): void => {
    const canvas = d3.select(canvasRef.current)
    const canvasWidth = (canvas.node() as SVGGraphicsElement).getBBox().width

    canvas.attr("viewBox", [0, 0, width, height])
    d3.select(axisRef.current).attr(
      "transform",
      `translate(${(width - canvasWidth) / 2}, ${height + heightDelta})`
    )
    d3.select(barWrapperRef.current).attr(
      "transform",
      `translate(${(width - canvasWidth) / 2}, ${height + heightDelta})`
    )
    d3.select(legendRef.current).attr(
      "transform",
      `translate(${(width - canvasWidth) / 2}, ${height + heightDelta})`
    )
  }, [height, width])

  /**
   * Truncate d3 text to match width and add ellipsis to the end of the text
   */
  function dotted(): void {
    const self = d3.select(this)
    let text = (self.data() as TimelineItem<number>[])[0].name
    let textLength = BrowserText.getWidth(text, 14, "sans-serif")

    let barWidth = (
      d3
        .select(`#${self.attr("id").replace("text", "bar")}`)
        .node() as SVGGraphicsElement
    )?.getBBox().width

    const padding = 17
    if (textLength > barWidth - padding && text.length > 0) {
      while (textLength > barWidth - padding && text.length > 0) {
        text = text.slice(0, -1).trimEnd()
        self.text(text + "...")
        textLength = BrowserText.getWidth(text, 14, "sans-serif")
      }
    } else {
      self.text(text)
    }
  }

  let BrowserText = (function () {
    let canvas =
      typeof document !== "undefined" ? document.createElement("canvas") : null
    let context = canvas?.getContext("2d")

    /**
     * Measures the rendered width of text given the font size and font face
     *
     * @param text The text to measure
     * @param fontSize The font size in pixels
     * @param fontFace The font face ("Arial", "Helvetica", etc.)
     *
     * @returns The width of the text
     *
     * @throws TypeError if context is undefined
     **/
    function getWidth(
      text: string,
      fontSize: number,
      fontFace: string
    ): number {
      if (!context) {
        throw new TypeError("Context is undefined")
      }
      context.font = fontSize + "px " + fontFace
      return context.measureText(text).width
    }

    return {
      getWidth: getWidth,
    }
  })()

  function drawBars(
    data: Array<TimelineItem<number>>,
    name: string,
    index: number,
    color: Color
  ): void {
    const tooltip = d3
      .select("body")
      .append("div")
      .classed("tooltip", true)
      .style("display", "none")
      .style("position", "absolute")

    function mouseover(
      this: SVGRectElement | SVGTextElement,
      _: any,
      d: TimelineItem<number>
    ) {
      tooltip.style("visibility", "visible").style("display", "inline")
      tooltip.html(
        `<strong>${d.name}</strong>` +
          "<br />" +
          `<span>${EraFuncs.fullYearToEraString(
            d.duration[0]
          )}\u2013${EraFuncs.fullYearToEraString(d.duration[1])}</span>`
      )

      if (d.link) {
        d3.select(this).style("cursor", "pointer")
      }
    }

    function mousemove(e: any): void {
      const xPad = 14
      const yPad = 20
      let y = e.pageY + yPad
      let x = e.pageX + xPad

      const c = d3.select(canvasRef.current).node()?.getBoundingClientRect()
      if (!c) {
        return
      }

      const w = tooltip.node()?.getBoundingClientRect().width || 0
      const h = tooltip.node()?.getBoundingClientRect().height || 0

      if (x + w + xPad > c.width) {
        x = e.pageX - w - xPad / 2
      }
      if (y + h + yPad > c.height) {
        y = e.pageY - h - yPad / 2
      }

      tooltip.style("top", y + "px").style("left", x + "px")
    }

    function mouseout() {
      tooltip.style("visibility", "hidden")
    }

    function click(_: any, d: TimelineItem<number>) {
      if (d.link) {
        d3.selectAll(".tooltip").remove()
        navigate(d.link)
      }
    }

    const barGroup = d3.select(barWrapperRef.current).selectAll(`${name}-bars`)

    barGroup
      .data(data)
      .enter()
      .append("rect")
      .classed("period-bar", true)
      .attr("height", 20)
      .attr("width", function (d) {
        return (d.duration[1] - d.duration[0]) * scale
      })
      .attr("x", function (d) {
        return getXPosition(d.duration[0], scale)
      })
      .attr("y", function () {
        return (index + 1) * heightDelta
      })
      .attr("fill", color.string())
      .attr("stroke", color.darken(0.2).string())
      .attr("id", function (d, i) {
        return `${d.name.replaceAll(" ", "-").toLowerCase()}-${i}-bar`
      })
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseout", mouseout)
      .on("click", click)

    barGroup
      .data(data)
      .enter()
      .append("text")
      .classed("period-text", true)
      .text(function (d) {
        return d.name
      })
      .attr("dy", ".75em")
      .attr("dx", ".75em")
      .attr("y", function () {
        return (index + 1) * heightDelta + 5
      })
      .attr("x", function (d) {
        return getXPosition(d.duration[0], scale, -5)
      })
      .attr("id", function (d, i) {
        return `${d.name.replaceAll(" ", "-").toLowerCase()}-${i}-text`
      })
      .attr("text-anchor", "start")
      .attr("font-family", "sans-serif")
      .attr("font-size", "14px")
      .attr("font-weight", "normal")
      .each(dotted)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseout", mouseout)
      .on("click", click)
  }

  /**
   * Generate tick x-values for all eras
   *
   * @param scale Scale from 1 to 100 yielding more ticks for higher number
   *
   * @returns The generated tick values
   *
   * @throws TypeError if scale is not a number and outside range 1-100
   */
  function getTickValues(scale: number = 1): Array<number> {
    if (typeof scale !== "number" || scale < 1 || scale > 100) {
      throw TypeError("Scale must be a number between 1 and 100")
    }

    const interval = 5 * Math.round(100 / scale)
    let range = new Array<number>()
    Object.keys(Eras).forEach(key => {
      const era = d3.range(Eras[key].start, Eras[key].end + 1, interval)
      if (key !== "imlerianEra") {
        era.shift()
        era.unshift(Eras[key].start + 1)
      }
      range = range.concat(era)
    })
    range.push(EraFuncs.getLastYear())

    return range
  }

  let gX: d3.Selection<SVGGElement | null, unknown, null, undefined>
  let axis: d3.Axis<d3.NumberValue>
  let axisScale: d3.ScaleLinear<number, number, never>
  function drawAxis(maxVal: number): void {
    axisScale = d3
      .scaleLinear()
      .domain([0, maxVal])
      .range([0, maxVal * scale])

    axis = d3.axisBottom(axisScale)

    axis
      .tickFormat(d => {
        return EraFuncs.fullYearToEraString(+d)
      })
      .tickValues(getTickValues())
      .tickSize(-height - 2 * heightDelta)

    gX = d3.select(axisRef.current).call(axis)
  }

  function drawChart(): void {
    /**
     * Check if number ranges intersect
     *
     * @param rangeA First range in comparison
     * @param rangeB Second range in comparison
     *
     * @returns True if ranges intersect, false otherwise
     */
    function intersects(rangeA: Array<number>, rangeB: Array<number>): boolean {
      return rangeA[0] < rangeB[1] && rangeA[1] > rangeB[0]
    }

    /**
     * Finds the index in the stack where the range will fit best
     *
     * @param range Number range to find index for
     * @param stack Object with index keys for ranges to be put
     *
     * @returns Index in stack for range to be put
     */
    function getStackIndex(range: Array<number>, stack: Object): number {
      const keys = Object.keys(stack)

      // Base case
      if (keys.length === 0) {
        return 0
      }

      // Check if range fits on existing index and does not intersect
      const res = keys.find(
        index =>
          !stack[index].some(element => intersects(range, element.duration))
      )

      if (res !== undefined) {
        return parseInt(res)
      } else {
        // Create new index
        return Math.max(...keys.map(item => parseInt(item))) + 1
      }
    }

    /**
     * Parse duration ranges which can contain years written on different
     * formats.
     *
     * @param duration Duration range
     *
     * @returns Durationg range with years on absolute form
     */
    function parseDuration(duration: Array<number | string>): Array<number> {
      return duration.map(item => {
        if (typeof item === "string") {
          return EraFuncs.eraStringToFullYear(item)
        }
        return item
      })
    }

    /**
     * Parse data object to ensure valid values.
     *
     * @param data Data object to parse
     *
     * @returns The parsed data object
     */
    function parseData(data: UnparsedTimlineData): TimelineData {
      return data.map(element => {
        return {
          ...element,
          items: element.items.map((item: TimelineItem<string | number>) => {
            return { ...item, duration: parseDuration(item.duration) }
          }),
        }
      })
    }

    // Clean up any existing elements before draw
    d3.selectAll(".period-bar").remove()
    d3.selectAll(".period-text").remove()

    drawAxis(EraFuncs.getLastYear())

    let offset = 0
    const parsedData = parseData(data)

    Object.keys(parsedData).forEach(key => {
      const barStack = {}

      let categoryData = parsedData[key].items
      categoryData.sort((a, b) => a.duration[0] - b.duration[0])

      categoryData.forEach(item => {
        const index = getStackIndex(item.duration, barStack)

        if (index in barStack) {
          barStack[index].push(item)
        } else {
          barStack[index] = [item]
        }
      })

      const color = new Color(parsedData[key].color)
      const keys = Object.keys(barStack)
      keys.forEach(key =>
        drawBars(barStack[key], `item-${key}`, parseInt(key) + offset, color)
      )
      offset += parseInt(keys.at(-1) || "") + 1
    })

    let overlappingTicks: Array<number> = []
    const zoom = d3
      .zoom()
      .scaleExtent([1, 100])
      .on("zoom", e => {
        const coefficient = scale * e.transform.k

        d3.select<SVGGElement, SVGRect | SVGTextElement>(".bar-wrapper")
          .selectAll("rect")
          .attr("width", function (d: TimelineItem<number> | unknown) {
            return (d.duration[1] - d.duration[0]) * coefficient
          })
          .attr("x", function (d) {
            return getXPosition(d.duration[0], coefficient, e.transform.x)
          })
        d3.selectAll(".period-text")
          .attr("x", function (d) {
            return getXPosition(d.duration[0], coefficient, e.transform.x - 5)
          })
          .each(dotted)
        axis.tickValues(getTickValues(e.transform.k))

        gX.call(axis.scale(e.transform.rescaleX(axisScale)))

        // Remove indices whenever the ticks change, i.e. on zoom event that
        // actually changes the scale, not just scrolling along the x-axis
        if (coefficient != lastZoomCoefficient) {
          overlappingTicks = getOverlappingTicks()
          lastZoomCoefficient = coefficient
        }
        removeTicks(overlappingTicks)
      })

    // Remove ticks on initial draw
    overlappingTicks = getOverlappingTicks()
    removeTicks(overlappingTicks)

    d3.select(canvasRef.current).call(zoom)

    legendRef && canvasRef && drawLegend(legendRef, width, parsedData)
  }

  /**
   * Removes all ticks with indices found in the provided array
   *
   * @param tickIndices List of tick indices to remove
   */
  function removeTicks(tickIndices: Array<number>): void {
    tickIndices.forEach(i => {
      d3.selectAll<SVGGElement, SVGLineElement | SVGTextElement>(".tick")
        .nodes()
        [i]?.remove()
    })
  }

  /**
   * Checks if two DOMrects are overlapping
   *
   * @param elementA The first DOMRect
   * @param elementB THe second DOMRect
   * @returns True if they overlap, false if they don't
   */
  function overlaps(elementA: DOMRect, elementB: DOMRect): boolean {
    if (elementA && elementB) {
      return (
        elementB.x < elementA.x + elementA.width &&
        elementB.x + elementB.width > elementA.x &&
        elementB.y < elementA.y + elementA.height &&
        elementB.y + elementB.height > elementA.y
      )
    }
    throw TypeError("DOM elements cannot be null")
  }

  /**
   * If any of the text elements of a axis tick overlaps with another,the
   * first of the two are removed
   *
   * @throws TypeError if text nodes are not accessible
   */
  function getOverlappingTicks(): Array<number> {
    const ticksToRemove = new Array<number>()
    let ticks = d3.selectAll<SVGGElement, SVGLineElement | SVGTextElement>(
      ".tick"
    )
    ticks.each(function (_, i) {
      const tickGroup = d3.select(this)
      const currentText = tickGroup.select<SVGTextElement>("text")

      if (i < ticks.size() - 1) {
        const nextTickGroup = d3.select(ticks.nodes()[i + 1])
        const nextTextElement = nextTickGroup.select<SVGTextElement>("text")

        const currentNode = currentText.node()
        const nextNode = nextTextElement.node()
        if (!currentNode || !nextNode) {
          throw TypeError("Could not get tick text node")
        }

        if (
          overlaps(
            currentNode.getBoundingClientRect(),
            nextNode.getBoundingClientRect()
          )
        ) {
          ticksToRemove.push(i)
        }
      }
    })
    return ticksToRemove
  }

  /**
   * Get X-position of timeline bars or text based on
   *
   * @param start Start point of timeline element
   * @param coefficient To
   * @param offset Left-hand offset
   *
   * @returns
   */
  function getXPosition(
    start: number,
    coefficient: number,
    offset: number = 0
  ): number {
    return start * coefficient + offset
  }

  return (
    <>
      <svg ref={canvasRef} className="canvas">
        <g ref={axisRef} className="axis" />
        <g ref={barWrapperRef} className="bar-wrapper" />
        <g ref={legendRef} className="legend" />
      </svg>
    </>
  )
}
