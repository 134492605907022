const EraLengths = {
  imlerianEra: 3333,
  firstEra: 1470,
  secondEra: 2501,
  thirdEra: 387,
}

interface PeriodRange {
  start: number
  end: number
  prefix: string
}

/**
 * Information about start and end of all eras
 */
export const Eras: { [key: string]: PeriodRange } = {
  imlerianEra: {
    start: 0,
    end: EraLengths.imlerianEra,
    prefix: "IE",
  },
  firstEra: {
    start: EraLengths.imlerianEra,
    end: EraLengths.imlerianEra + EraLengths.firstEra,
    prefix: "1E",
  },
  secondEra: {
    start: EraLengths.imlerianEra + EraLengths.firstEra,
    end: EraLengths.imlerianEra + EraLengths.firstEra + EraLengths.secondEra,
    prefix: "2E",
  },
  thirdEra: {
    start: EraLengths.imlerianEra + EraLengths.firstEra + EraLengths.secondEra,
    end:
      EraLengths.imlerianEra +
      EraLengths.firstEra +
      EraLengths.secondEra +
      EraLengths.thirdEra,
    prefix: "3E",
  },
}

/**
 * Convenience methods for handling years in different eras.
 */
export class EraFuncs {
  /**
   * Convert year relative to start of Imlérian age into year relative to era.
   *
   * @param year Absolute form year
   *
   * @returns Era-relative year
   *
   * @throws TypeError if year is not an integer or negative
   */
  static fullYearToEraString(year: number): string {
    if (!Number.isInteger(year)) {
      throw TypeError("Year must be an integer")
    }

    if (year < 0) {
      throw TypeError("Year cannot be negative")
    }

    let prefix = ""
    let localYear = year
    if (year <= Eras.imlerianEra.end) {
      prefix = Eras.imlerianEra.prefix
    } else if (year <= Eras.firstEra.end) {
      prefix = Eras.firstEra.prefix
      localYear -= Eras.firstEra.start
    } else if (year <= Eras.secondEra.end) {
      prefix = Eras.secondEra.prefix
      localYear -= Eras.secondEra.start
    } else {
      prefix = Eras.thirdEra.prefix
      localYear -= Eras.thirdEra.start
    }

    return `${prefix} ${localYear}`
  }

  /**
   * Convert string representing year relative to era into year as number
   * relative to the start of the first age. String must match the regex:
   * [1-4Ii][Ee][0-9]+
   *
   * @param year Era-relative year
   *
   * @returns Absolute form year
   *
   * @throws TypeError if year parameter did not match the regex
   */
  static eraStringToFullYear(year: string): number {
    const yearRegex = "[1-4Ii][Ee][0-9]+"
    const str = year.match(yearRegex)
    if (str?.length !== 1) {
      throw new TypeError(
        `Year string \"${year}\" did not match the regex: ${yearRegex}`
      )
    }
    const eraComponents = str[0].split(RegExp("[Ee]", "ig"))
    // Cast to string since regex will ensure find() never returns undefined
    const eraName = Object.keys(Eras).find(
      key => Eras[key].prefix === eraComponents[0].toUpperCase() + "E"
    ) as string

    const eraOffset = Eras[eraName]!.start
    const relativeYear = parseInt(eraComponents[1])

    return eraOffset + relativeYear
  }

  /**
   * Get last year in history.
   *
   * @returns Last year
   */
  static getLastYear(): number {
    return Eras.thirdEra.end
  }
}
