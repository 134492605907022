import React, { useEffect } from "react"
import Seo from "../components/Seo"
import Timeline from "../components/timeline/Timeline"
import data from "../components/timeline/timeline-data.json"
import { Home } from "@mui/icons-material"
import { navigate } from "gatsby"

export default function TimelinePage() {
  useEffect(() => {
    document.body.classList.add("no-padding")
    return () => document.body.classList.remove("no-padding")
  }, [])

  return (
    <>
      <Seo title="Timeline" />
      <div className="canvas-wrapper">
        <button
          className="home-button"
          title="Home"
          onClick={() => navigate("/")}
        >
          <Home className="home-icon" />
        </button>
        <Timeline
          data={data}
          width={typeof window !== "undefined" ? window.innerWidth : 0}
          height={typeof window !== "undefined" ? 900 : 0}
        />
      </div>
    </>
  )
}
